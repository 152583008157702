<template>
  <div class="">
    <button
      type="button"
      class="btn btn-info"
      @click="toggle = !toggle">
      เปลี่ยนรหัสผ่าน
    </button>

    <div v-if="toggle" class="mt-3 card">
      <div class="card-body">
        <h5 class="card-title">เปลี่ยนรหัสผ่าน</h5>

        <div class="form-row">
          <sgv-input-text
            class="col-12"
            label="username"
            v-model="formData.username"
            :validations="[
              {text: 'required!', value: $v.formData.username.$dirty && !$v.formData.username.required}
            ]">
          </sgv-input-text>

          <sgv-input-password
            class="col-12"
            label="password"
            v-model="formData.password"
            :validations="[
              {text: 'required!', value: $v.formData.password.$dirty && !$v.formData.password.required}
            ]">
          </sgv-input-password>

          <sgv-input-password
            class="col-12"
            label="confirm password"
            v-model="formData.confirm"
            :validations="[
              {text: 'required!', value: $v.formData.confirm.$dirty && !$v.formData.confirm.required},
              {text: 'รหัสผ่านไม่ตรงกัน', value: $v.formData.confirm.$dirty && !$v.formData.confirm.sameAs}
            ]">
          </sgv-input-password>
        </div>

        <button
          type="button"
          class="btn btn-warning mr-2"
          @click="resetUsername">
          แก้ไข
        </button>

        <button
          type="button"
          class="btn btn-primary"
          @click="toggle = false">
          ยกเลิก
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'
import { RESET_USERNAME } from './graph'

export default {
  props: {
    userId: {
      type: Number,
      required: true
    },
    userType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        username: '',
        password: '',
        confirm: ''
      }
    }
  },
  validations: {
    formData: {
      username: {required},
      password: {required},
      confirm: {
        required,
        sameAs: sameAs(function() {
          return this.formData.password
        })
      },
    }
  },
  methods: {
    resetUsername () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: RESET_USERNAME(this.templateType),
        variables: {
          userType: this.userType,
          userId: this.userId,
          username: this.formData.username,
          password: this.formData.password
        }
      })
      .then(() => {
        this.setDefault()
        this.toggle = false
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    setDefault () {
      this.$v.formData.$reset()
      this.formData.username = ''
      this.formData.password = ''
      this.formData.confirm = ''
    }
  }
}
</script>

<style lang="css" scoped>
</style>
