<template>
  <div class="">

    <div v-if="method ==='edit'">
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <ul>
        <li v-for="item in items" :key="item.id">
          <fa
            icon="check"
            class="text-success pointer"
            @click="addGroup(item)">
          </fa>&nbsp;
          {{item.code}} <span v-show="item.name">({{item.name}})</span>
        </li>
      </ul>
    </div>

    Groups:
    <ul>
      <li v-for="group in groups" :key="group.id">
        <fa
          v-if="method ==='edit'"
          icon="trash"
          class="text-danger pointer"
          @click="deleteGroup(group.id)">
        </fa>&nbsp;
        {{group.code}} <span v-show="group.name">({{group.name}})</span>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  LIST_GROUP,
  CREATE_GROUP_USER,
  DESTROY_GROUP_USER
} from './graph'
import debounce from 'lodash/debounce'

export default {
  props: {
    userId: {
      type: Number,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    userType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  computed: {
    groups () {
      return this.formData.groups.filter(v => {
        const name = v.name || ''
        const isCodeFound = v.code.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        const isNameFound = name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        return isCodeFound || isNameFound
      })
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: LIST_GROUP(this.templateType),
        variables: {
          userType: this.userType,
          q: filter
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.groups
      })
      .catch(() => this.items = [])
    }, 150),
    addGroup (group) {
      this.$apollo.mutate({
        mutation: CREATE_GROUP_USER(this.templateType),
        variables: {
          userType: this.userType,
          userId: this.userId,
          groupId: group.id
        }
      })
      .then(() => {
        this.addGroups(group)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    deleteGroup (groupId) {
      this.$apollo.mutate({
        mutation: DESTROY_GROUP_USER(this.templateType),
        variables: {
          userType: this.userType,
          userId: this.userId,
          groupId
        }
      })
      .then(() => {
        this.removeGroups(groupId)
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitGroups (arr) {
      this.$emit('groups', arr)
    },
    addGroups (group) {
      const index = this.formData.groups.findIndex(v => v.id === group.id)
      if (index === -1) this.formData.groups.unshift(group)
    },
    removeGroups (groupId) {
      const index = this.formData.groups.findIndex(v => v.id === groupId)
      this.formData.groups.splice(index, 1)
    },
  },
  watch: {
    search (value) {
      if (value) {
        this.getList({
          limit: 20,
          params: {
            search: value
          },
          order: 'code'
        })
      } else {
        this.items = []
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
