var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_vm._v(" เปลี่ยนรหัสผ่าน ")]),(_vm.toggle)?_c('div',{staticClass:"mt-3 card"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("เปลี่ยนรหัสผ่าน")]),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"label":"username","validations":[
            {text: 'required!', value: _vm.$v.formData.username.$dirty && !_vm.$v.formData.username.required}
          ]},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}}),_c('sgv-input-password',{staticClass:"col-12",attrs:{"label":"password","validations":[
            {text: 'required!', value: _vm.$v.formData.password.$dirty && !_vm.$v.formData.password.required}
          ]},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('sgv-input-password',{staticClass:"col-12",attrs:{"label":"confirm password","validations":[
            {text: 'required!', value: _vm.$v.formData.confirm.$dirty && !_vm.$v.formData.confirm.required},
            {text: 'รหัสผ่านไม่ตรงกัน', value: _vm.$v.formData.confirm.$dirty && !_vm.$v.formData.confirm.sameAs}
          ]},model:{value:(_vm.formData.confirm),callback:function ($$v) {_vm.$set(_vm.formData, "confirm", $$v)},expression:"formData.confirm"}})],1),_c('button',{staticClass:"btn btn-warning mr-2",attrs:{"type":"button"},on:{"click":_vm.resetUsername}},[_vm._v(" แก้ไข ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){_vm.toggle = false}}},[_vm._v(" ยกเลิก ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }